


























































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { QueryLoadOption, SortingInfo } from "@/models/QueryLoadOptions";
import { ActivityService } from "@/services/customer/ActivityService";
import { LoadResult } from "@/models/LoadResult";
import vSelect from 'vue-select';
import { CallLogResult, OptionCallType } from "@/models/customer/activity/CallLogResult";
import { CallType } from "@/constants/callType";
import debounce from 'lodash/debounce';
import each from 'lodash/each';
import { Common } from '@/helper/common';
import AudioComponent from "@/components/audio/AudioComponent.vue";
import moment from "moment";

@Component({
    components: {
        'v-select': vSelect,
        'audio-component': AudioComponent,
    }
})

export default class CustomerCallList extends Vue {
    @Ref('audio') audio!: any;
    searchTerm = "";
    totalPages = 0;
    currentPage: number = 1;
    pageSize = 20;
    data: any[] = [];
    types = [new OptionCallType(CallType.callIn),
        new OptionCallType(CallType.callOut)];
    callTypes: OptionCallType[] = [];

    debounce: any = null;
    // Search loadOption
    loadOptions: QueryLoadOption = new QueryLoadOption();
    common = new Common();
    recorderFileAudio: string = '';
    callType = CallType;

    private activityService: ActivityService = new ActivityService();

    constructor() {
        super();
        this.loadOptions.select = ['id', 'calldate', 'dst', 'duration', 'billsec', 'accountcode', 'recordingfile',
            'src', 'content', 'callType', 'disposition'];
        this.loadOptions.sort = [new SortingInfo('calldate', true)];
    }

    beforeMount() {
    }

    mounted() {
        this.search();
    }

    @Watch('searchTerm')
    searchByTerm(value: string) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.rendererQuery();
        this.debounce = debounce(() => this.search(), 500);
        this.debounce();
    }

    beginSearch(page: number) {
        if (this.searchTerm == undefined || this.searchTerm.length < 2) {
            return;
        }

        this.search();
    }

    getCallTypeName(type: CallType) {
        switch (type) {
            case CallType.callIn:
                return 'Gọi đến';
            case CallType.callOut:
                return 'Gọi đi';
            default:
                return '';
        }
    }

    selectValue(values: OptionCallType[]) {
        this.callTypes = values;
        this.rendererQuery();
        this.currentPage = 1;
        this.search();
    }

    callAudio(item: CallLogResult) {
        this.audio.show(item.recordingfile !== this.recorderFileAudio, item.recordingfile);
        if (item.recordingfile !== this.recorderFileAudio) {
            this.recorderFileAudio = item.recordingfile;
        } else {
            this.recorderFileAudio = '';
        }
    }

    formatDate(date: any) {
        return moment(date).format('DD-MM-YYYY HH:mm')
    }

    private search() {
        this.loadOptions.skip = (this.currentPage - 1) * this.pageSize;
        this.loadOptions.take = this.pageSize;
        this.loadOptions.requireTotalCount = true;
        const customerId = this.$route.params.id;

        this.$vs.loading();

        this.activityService.searchCallLog(this.loadOptions, customerId).finally(() => {
            this.$vs.loading.close();
        }).then((result: LoadResult) => {
            this.data = result.data;
            this.setTotalPage(result.totalCount);
        })
    }

    private setTotalPage(totalRows: number) {
        if (totalRows == 0) {
            this.totalPages = 0;
            return 0;
        }

        if (totalRows <= this.pageSize) {
            this.totalPages = 1;
            return 1;
        }

        this.totalPages = Math.ceil((totalRows / this.pageSize));
    }

    private rendererQuery() {
        const paramsKeyword: any[] = [];
        if (this.searchTerm !== null && this.searchTerm !== null) {
            paramsKeyword.push(this.common.renderQuery('src', "contains", this.searchTerm));
            paramsKeyword.push('or');
            paramsKeyword.push(this.common.renderQuery('dst', "contains", this.searchTerm));
        }

        const paramsType: any[] = [];
        if (this.callTypes && this.callTypes.length > 0) {
            each(this.callTypes, (item: OptionCallType, index: number) => {
                paramsType.push(this.common.renderQuery('callType', "=", item.id.toString()))
                if (index < this.callTypes.length - 1) {
                    paramsType.push('or');
                }
            })
        }

        const params: any = [];
        if (paramsKeyword && paramsKeyword.length > 0) {
            params.push(paramsKeyword);
        }

        if (paramsType && paramsType.length > 0) {
            if (paramsKeyword && paramsKeyword.length > 0) {
                params.push('and');
            }

            params.push(paramsType);
        }

        this.loadOptions.filterQuery = params && params.length > 0 ? JSON.stringify(params) : '';
    }
}
