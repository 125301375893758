import {CallType} from "@/constants/callType";

export class CallLogResult {
    id: number;
    calldate?: Date;
    dst: string;// Máy nhận
    dcontext: string;
    channel: string;
    duration: number;
    billsec: number;
    disposition: string;
    accountcode: string;
    uniqueid: string;
    userfield: string;
    recordingfile: string;
    did: string;
    src: string;
    contactId: number;
    customerId: number;
    content: string; // Nội dung trao đổi với KH
    callType: CallType;
    products: string;
    potentialLevel: number;
}

export class OptionCallType {
    id: CallType;
    name: string;

    constructor(id: CallType) {
        this.id = id;
        switch (id) {
            case CallType.callIn:
                this.name = 'Gọi đến';
                break;
            case CallType.callOut:
                this.name = 'Gọi đi';
                return;
            default:
                this.name = '';
                return;
        }
    }
}