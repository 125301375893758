









import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({})
export default class AudioComponent extends Vue {
    isShow: boolean = false;
    recordFileAudio: string = '';

    constructor() {
        super();
    }

    show(isShow: boolean, recordFileAudio: string) {
        this.isShow = isShow;
        if (this.isShow) {
            this.recordFileAudio = recordFileAudio;
        }
    }
}
